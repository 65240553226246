import { DEL_ITEM_PERMISSION, WEB_ANALYTICS_PPVF_TYPE_A_ITEMS } from "@kraftheinz/common";
import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Maintain List of Type A Items to Include", "ph-search-by": "Search by Item Code", "has-active": false, "search-by": "ItemCode", "has-filter-inactive": false, "create-button-text": "Add Item" }, scopedSlots: _vm._u([{ key: "customFilter", fn: function() {
    return [_c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v("Show Items for Country")]), _c("div", { staticClass: "country-filter mr-2" }, [_c("select-filter", { key: "Country", attrs: { "source": "countryId", "placeholder": "Country", "data-source": _vm.countries, "source-label": _vm.generateCountryLabel, "value": _vm.selectedCountry }, on: { "change": function($event) {
      return _vm.onSelectFilterChange($event, "CountryID");
    } } })], 1)];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var record = ref.record;
    return [_vm.can(_vm.permissions.del) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onDelete(record);
    } } })], 1) : _vm._e()];
  } }]) }, [_c("url-field", { key: "ItemCode", attrs: { "data-index": "itemCode", "title": "Item Code", "clickable": false, "base-url": "" } }), _c("text-field", { key: "ItemDesc", attrs: { "data-index": "itemDesc", "title": "Item Desc" } })], 1);
};
var staticRenderFns$1 = [];
var TypeAItems_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".country-filter[data-v-1f8265b0]{min-width:160px}\n")();
const __vue2_script$1 = {
  name: "TypeAItems",
  inject: ["crud", "can"],
  mixins: [MyUtil],
  data() {
    return {
      permissions: {
        del: DEL_ITEM_PERMISSION
      }
    };
  },
  created() {
    this.getCountries(false);
  },
  methods: {
    onDelete(record) {
      this.$confirm({
        class: "list__modal-delete",
        icon: "delete",
        title: `Do you want to delete Item?`,
        content: `If you click Yes, you won\u2019t be able to undo this Delete operation. Are you sure you want delete Item?`,
        cancelText: "No",
        okText: "Delete",
        okType: "danger",
        onOk: this.onConfirmDelete(record)
      });
    },
    onConfirmDelete(record) {
      return () => {
        this.axios.put(`${this.apiUrl}/web-analytics/include-type-a`, {
          CountryID: record.countryID,
          ItemCode: record.itemCode,
          UpdateType: "delete"
        }).then(() => {
          this.crud.fetchList();
          this.$notification["success"]({
            message: "Item Deleted!"
          });
        }).catch(() => {
          this.$notification["error"]({
            message: "Something went wrong, please try again!"
          });
        });
      };
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "1f8265b0", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var TypeAItems = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "web-analytics.include-type-a", "api-url": _vm.apiUrl, "resource-id-name": "itemCode", "create-route": "/web-analytics/include-type-a/create", "page": _vm.page } }, [_c("type-a-items")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    TypeAItems
  },
  data() {
    return {
      page: WEB_ANALYTICS_PPVF_TYPE_A_ITEMS,
      apiUrl,
      TypeAItems,
      itemsMenu: [
        {
          key: "ppv-forecast",
          title: "PPV-Forecast",
          path: ""
        },
        {
          key: "extra-costs",
          title: "Maintain List of Type A Items to Include",
          path: "/web-analytics/include-type-a"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
